//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapMutations} from 'vuex'
import $ from "jquery";

import api from "@/api/api"
import utils from "@/utils";
import CertificatesModal from "@/components/documents/modals/CertificatesModal";
import SignPepModal from "@/components/documents/modals/SignPepModal";
import SettingIcon from "@/components/icons/SettingIcon";
import SettingSelectIcon from "@/components/icons/SettingSelectIcon";
import TodayDocuments from "@/components/icons/TodaysDocumentsIcon";
import TodaySelectDocuments from "@/components/icons/TodaysDocumentsSelectIcon";
import TodayDocumentsMob from "@/components/icons/TodaysDocumentsMobIcon";
import TodaySelectDocumentsMob from "@/components/icons/TodaysDocumentsSelectMobIcon";
import notification from "@/notification/notification";

export default {
  name: "DropdownSelectDocuments",
  props: {
    documents: Array,
    isDocuments: Boolean,
    isPersonnelDocuments: Boolean,
    total: Number,
    limit: Number,
    setShowPreviewLoader: Function,
    setFilter: Function,
    getDocuments: Function,
    setToday: Function,
    isTodayDocument: Boolean,
    todayDocumentToggle: Function,
    filterToggle: Function,
    signRules: Object,
    ecSignRules: Array,
  },
  components: {
    SettingIcon,
    SettingSelectIcon,
    TodayDocuments,
    TodaySelectDocuments,
    TodayDocumentsMob,
    TodaySelectDocumentsMob,
    CertificatesModal,
    SignPepModal
  },
  data() {
    return {
      showCertificatesModal: false,
      showSignPepModal: false,
      selectedDocuments: [],
    }
  },
  computed: {
    selected() {
      let res = this.documents.filter(function (item) {
        return item.checked
      })
      res = res.map(function (item) {
        return item.id
      })

      return res
    },
    countDocsOfPage() {
      if (this.limit > this.total) {
        return this.total;
      }
      return this.limit
    },
    checkedDocuments() {
      return this.documents.filter((document) => document.checked);
    },
    selectedDocumetsId() {
      return this.selectedDocuments && this.selectedDocuments.map((document) => document.id) || [];
    },
    documentType() {
      return this.isPersonnelDocuments
        ? 'persDoc' : 'concreteDoc'
    },
  },
  methods: {
    showPreview(id, previewLink = null) {
      if (previewLink) {
        this.$router.push({
          name: 'document',
          params: {
            link: previewLink,
            type: this.isDocuments ? 'doc' : 'personnel'
          }
        })
      } else {
        this.setShowPreviewLoader(true)

        const response = this.isDocuments
            ? api.getDocumentsPreview(id)
            : api.getDocumentsPreview(id, 'persDoc')

        response.then(res => {
          this.setShowPreviewLoader(false)
          if (!res.data.result || !res.data.result.previewLink) {
            return
          }

          const params = {
            name: 'document',
            params: {
              link: res.data.result.previewLink,
              type: res.data.result.type || 'doc'
            }
          }

          if (this.isPersonnelDocuments) {
            params.query = {
              type: 'personnel'
            }
          }

          window.location.href = res.data.result.previewLink;
        })
      }
    },
    showGetArchiveModalSelected() {
      this.setIdDocuments(this.selected)
      $('#modalGetArchive').modal('show')
    },
    showSelected() {
      this.showPreview(this.selected)
    },
    downloadsStudentDocumentsWithSign() {
      utils.downloadStudentDocsWithSign(this.selected)
    },
    signSelectedDocuments() {
      const selectedDocuments = this.checkedDocuments;
      const containsNotAllowedSignDocuments = selectedDocuments.some(document => !document.signAllowed || document.signOnDateOnly);
      if (containsNotAllowedSignDocuments) {
        const selectedDocumentsSignOnDateOnly = selectedDocuments.filter(document => document.signOnDateOnly);
        const selectedDocumentsAllowedSign = selectedDocuments.filter((document) => !document.signAllowed);
        if (selectedDocumentsAllowedSign.length) {
          notification.error(`<div class='text-justify'>${this.$t('documents.contains_forbidden_sign')}</div>`);
        }
        if (selectedDocumentsSignOnDateOnly.length) {
          const forbiddenDocumentsNames = selectedDocumentsSignOnDateOnly.reduce((prev, next) => prev+next.name+'<br/>', '')
          notification.error(`<div class='text-justify'>${this.$t('documents.contains_forbidden_sign_on_date')}<br/>${forbiddenDocumentsNames}</div>`)
        }
      } else {
        this.selectedDocuments = selectedDocuments;
        this.showCertificatesModal = true;
      }
    },
    hideCertificatesModal() {
      this.selectedDocuments = [];
      this.showCertificatesModal = false;
    },
    signPep() {
      if (this.showCertificatesModal) 
        this.showCertificatesModal = false;
      this.showSignPepModal = true;
    },
    closePepModal(signed = false, back = false) {
      this.showSignPepModal = false;
      if(back) 
        this.showCertificatesModal = true;
      if(signed)
        this.getDocuments(this.$store.state.documentPageLimit, this.$store.state.documentPageNumber);
      if(!back && !this.isExternal) 
        utils.removeModalOpenClass();
    },
    ...mapMutations([
      'setIdDocuments'
    ])
  },
}
