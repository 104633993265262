import { render, staticRenderFns } from "./TheDocumentContent.vue?vue&type=template&id=acf44ab2&scoped=true&"
import script from "./TheDocumentContent.vue?vue&type=script&lang=js&"
export * from "./TheDocumentContent.vue?vue&type=script&lang=js&"
import style0 from "./TheDocumentContent.vue?vue&type=style&index=0&id=acf44ab2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf44ab2",
  null
  
)

export default component.exports