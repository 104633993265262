//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import notification from "@/notification/notification";
import {createSign, getCertificateKepNames, getCertificateNep} from "@/sign/sign";
import api from "@/api/api";
import CertificateItems from "@/components/documents/modals/CertificateItems";
import moment from "moment";
import CertificateBlock from "@/components/documents/modals/CertificateBlock";
import ErrorInfoIcon from "@/components/icons/ErrorInfoIcon";
import Loader from "@/components/main/Loader";
import {mapGetters, mapState} from "vuex";
import {getDocSignerForWorker, getSignParams} from "@/sign/operations";
import SendToRvrBtn from "@/components/documents/btns/SendToRvrBtn";
import rvr from "@/document/rvr";
import AlertInfoIcon from "@/components/icons/AlertInfoIcon";

export default {
  name: "CertificatesModal",
  components: {
    Loader, 
    ErrorInfoIcon, 
    CertificateBlock, 
    CertificateItems, 
    SendToRvrBtn, 
    AlertInfoIcon,
  },
  props: {
    documents: [Object, Array],
    hide: Function,
    getDocuments: Function,
    documentType: String,
    changeDocument: Function,
    forceRender: Function,
    signRules: Object,
    ecSignRules: [Array, Object],
    signPep: Function,
    allowedSign: Array
  },
  data() {
    return {
      allCertificates: [],
      documentHash: null,
      selectedCertificate: null,
      isGetCertificatesError: false,
      certificatesError: this.$t('documents.certificatesError'),
      createSignError: this.$t('documents.certificatesError'),
      isPluginError: false,
      pluginError: this.$t('documents.pluginError'),
      attention: this.$t('documents.attention'),
      chooseCertificate: this.$t('documents.chooseCertificate'),
      modalId: 'modalCertificate',
      dateFormat: 'DD.MM.YY',
      certificateDateFormat: 'DD.MM.Y HH:mm:ss',
      isLoader: false,
      isRvrLoader: false,
      rvrImage: require('@/assets/img/rvr-image.png'),
      signer: {},
      isMobile: this.$isMobile(),
    }
  },
  mounted() {
    this.getCertificates()
    this.setSigner()
  },
  computed: {
    documentHasOneSigner() {
      return this.document.signers.length === 1;
    },
    canShowRvrError() {
      const isExternal = this.document.external;
      const isExtraSigner = !this.userIsBasic;
      return !((isExternal && isExtraSigner) || (!isExternal));
    },
    certificateBlocksFlexClass() {
      return this.isAllowGoToRvr ? "row-flex" : "";
    },
    userIsBasic() {
      if (this.document.workerIsBasicSigner) {
        return this.document.workerIsBasicSigner;
      } else {
        return this.document.signers.filter(signer => signer.basic && signer.workerId == this.currentStudent.ID).length > 0;
      }
    },
    isSelectedActive() {
      return this.isCertificateActive(this.selectedCertificate)
    },
    canBeSelected() {
      const self = this;
      return function (certificate) {
        if(!self.ecSignRules && Object.getOwnPropertyDescriptor(self.signRules, self.document.type) === undefined) {
          return true;
        }
        if (self.ecSignRules && Object.getOwnPropertyDescriptor(self.ecSignRules.RULES, self.document.type) === undefined) {
          return true;
        }

        switch (certificate.type) {
          case "personal":
            if (self.ecSignRules) 
              return self.document.isIAmResponsible ? self.ecSignRules[self.document.type].UF_SIGN_NEP_RESP : self.ecSignRules[self.document.type].UF_SIGN_NEP;
            else
              return self.document.isIAmResponsible ? self.signRules[self.document.type].UF_SIGN_NEP_RESP : self.signRules[self.document.type].UF_SIGN_NEP
          default:
            if (self.ecSignRules) 
              return self.document.isIAmResponsible ? self.ecSignRules[self.document.type].UF_SIGN_NEP_RESP : self.ecSignRules[self.document.type].UF_SIGN_NEP;
            else
              return self.document.isIAmResponsible ? self.signRules[self.document.type].UF_SIGN_KEP_RESP : self.signRules[self.document.type].UF_SIGN_KEP
        }
      }
    },
    isPersonalCertificate() {
      return this.selectedCertificate && this.selectedCertificate.type === 'personal';
    },
    isShowAllowGetLinkError() {
      return this.isAllowGoToRvr && this.allowSendToTrudVsem && this.document.allowGetLinkError && this.canShowRvrError;
    },
    isAllowGoToRvr() {
      return Boolean(this.document.external && this.allowedRvr && this.userIsBasic && !this.documentHasOneSigner);
    },
    isAllowedPep() {
      let checkPep = (document) => {
        let allow = false;
        const isEcDoc = document.fromExternalEducation;
        if (isEcDoc) {
          const [ruleSource, ruleFiled] = isEcDoc && document.isIAmResponsible ? [document.studentCompanyId, 'CLIENT_ID'] : [document.ecCompanyId, 'EC_ID'];
          const ecRules = (Array.isArray(this.ecSignRules)) ? this.ecSignRules.find(rule => rule[ruleFiled] == ruleSource).RULES : this.ecSignRules;
          if (document.isIAmResponsible) {
            allow = ecRules[document.type] && ecRules[document.type].UF_SIGN_PEP_RESP;
          } else {
            allow = ecRules[document.type] && ecRules[document.type].UF_SIGN_PEP;
          }
        } else {
          if (document.isIAmResponsible) {
            allow = this.signRules[document.type].UF_SIGN_PEP_RESP;
          } else {
            allow = this.signRules[document.type].UF_SIGN_PEP;
          }
        }
        return allow;
      }
      if (Array.isArray(this.documents)) {
        return this.documents.every(checkPep);
      }
      return checkPep(this.documents);
    },
    document() {
      if (Array.isArray(this.$props.documents)) {
        return this.$props.documents[0];
      } else {
        return this.$props.documents;
      }
    },
    allowedPep() {
      return !this.allowedSign || (this.allowedSign && this.allowedSign.includes('pep'));
    },
    allowedRvr() {
      return !this.allowedSign || (this.allowedSign && this.allowedSign.includes('rvr'));
    },
    allowedCertificate() {
      return !this.allowedSign || (this.allowedSign && this.allowedSign.includes('certificate'));
    },
    ...mapGetters([
      'isDsExpiredStatus',
      'isDsApprovedStatus',
    ]),
    ...mapState([
      'currentStudent',
      'allowSendToTrudVsem'
    ])
  },
  methods: {
    isCertificateActive(certificate) {
      if (!certificate || !certificate.ValidToDate) {
        return false
      }

      const certificateValidToDate = moment(certificate.ValidToDate).toDate()

      if (!(certificateValidToDate instanceof Date)) {
        return false
      }

      const date = new Date()

      return (certificateValidToDate >= date)
    },
    backdropClick(e) {
      if (e.target.id && e.target.id === this.modalId) {
        this.hide()
      }
    },

    getFormattedDate(date, format = this.certificateDateFormat) {
      return moment(date).format(format)
    },

    setSelectedCertificate(certificate) {
      this.selectedCertificate = certificate
    },

    getCertificates() {
      this.isGetCertificatesError = false
      this.isPluginError = false

      getCertificateKepNames().then((certs) => {
        this.allCertificates = certs
        this.addCertificateNep();
      }).catch((e) => {
        this.addCertificateNep();
        if (e === 'plugin_not_found') {
          this.isPluginError = true
        } else {
          this.isGetCertificatesError = true
        }
      })
    },
    addCertificateNep() {
      if (Object.keys(getCertificateNep()).length && (this.isDsExpiredStatus || this.isDsApprovedStatus)) {
        this.allCertificates.push(getCertificateNep());
      }
    },
    getFileContent(file) {
      var header = ";base64,";
      var fileData = file;
      return fileData.substr(fileData.indexOf(header) + header.length);
    },
    sendSign(sign = null) {
      const signParams = getSignParams(this.document.id, this.documentType, this.selectedCertificate, false, sign)

      api.sign(signParams).then((result) => {
        if (result.data && result.data.result) {
          notification.success(this.$i18n.t('documents.signed_succes'))
          this.changeDocument(result.data.result.document);
          this.hide()
          if (this.forceRender) {
            this.forceRender()
          }
        }
      }).catch((e) => {
        console.log(e);
        notification.error(this.$i18n.t('certificates_modal.send_error'))
      }).finally(() => {
        this.isLoader = false
      })
    },
    goToRvr() {
      this.isRvrLoader = true;
      if (this.signer === null) {
        notification.error(this.$i18n.t('rvr_modal.errors.dont_allow_sign_this_doc'))
        this.isRvrLoader = false
        return
      }

      if (this.signer.linkToRvr) {
        this.isRvrLoader = false
        window.open(this.signer.linkToRvr)
        return
      }

      api.getLinkToTrudVsem(rvr.getParamsForGetLinkRequest(this.document)).then((result) => {
        if (result.data && result.data.result && result.data.result.link) {
          this.signer.linkToRvr = result.data.result.link
          window.open(result.data.result.link)
        }
      }).catch((e) => {
        console.log(e)
        notification.error(this.$i18n.t('certificates_modal.send_error'))
      }).finally(() => {
        this.isRvrLoader = false
      })
    },
    async createAndSendSign(fileContent) {
      createSign(this.selectedCertificate.SerialNumber, fileContent).then((sign) => {
        this.sendSign(sign)
      }).catch((err) => {
        let invalideCertificateErrorCode = "0x80090010";
        if (err.indexOf(invalideCertificateErrorCode) != -1) {
          notification.error(this.$i18n.t('certificates_modal.invalid_certificate'))
        } else {
          notification.error(this.$i18n.t('certificates_modal.sign_error'))
        }
      }).finally(() => {
        this.isLoader = false
      })
    },
    async signDocument() {
      if (!this.selectedCertificate || !this.canBeSelected || this.isLoader) {
        return
      }

      this.isLoader = true

      if (this.isPersonalCertificate) {
        this.sendSign()
        return
      }

      const downloadResponse = await api.downloadDoc(this.document.id, this.documentType, true)

      if (downloadResponse && downloadResponse.data && downloadResponse.data.result && downloadResponse.data.result.src) {
        let url = new URL(downloadResponse.data.result.src)
        url.searchParams.set('sign', 0)

        const docUrl = url.toString()

        try {
          fetch(docUrl)
            .then(res => res.ok ? res.blob() : Promise.reject(res))
            .then(file => {
              if (!file) {
                notification.error(this.$i18n.t('certificates_modal.file_error'))
                this.isLoader = false
                return
              }

              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const fileContent = this.getFileContent(reader.result)

                this.createAndSendSign(fileContent)
              };
            })
            .catch(() => {
              this.isLoader = false
              notification.error(this.$i18n.t('certificates_modal.file_error'))
            });
        } catch (e) {
          this.isLoader = false
          console.log(e);
          notification.error(this.$i18n.t('certificates_modal.file_error'))
        }

      }
    },
    setSigner() {
      this.signer = getDocSignerForWorker(this.document);
    },
    handleSignPep() {
      if (this.isAllowedPep) this.signPep();
    },
  }
}
